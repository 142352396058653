import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  headline: {
    fontSize: 3,
    fontWeight: 'light'
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader=''
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            {/* <Button sx={styles.button}>Contact Me</Button> */}
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
            <Text variant='p' sx={styles.headline}>
                I decided to retire the old blog and start fresh for 2022. For one, it was built on the security nightmare that is Wordpress, but it was starting to feel a bit dated which gave me a reason to work with something I haven't before, in this case Gatsby. 
              </Text>
              <hr/>
              <br />
              <Section title='My Story'>
                <Text variant='p'>
                  I got my start in web development as a kid, building GeoCities web sites for people outside of school and to promote my future music career.  
                  Well, the music career didn't happen and I'm probably aging myself with that GeoCities reference, but it was the beginning of a future in web programming. 
                </Text>
                <Text variant='p'>
                  After college I went on to start a web site design and development agency, where I soon found a passion for creating single page web applications, which at the time was really only possible
                  with the Adobe Flash Platform, specifically the Flex framework.  
                </Text>
                <Text variant='p'>
                  I became quite the ActionScript guru, making contributions to the Flex framework, and writing a programming book on the subject that was available through all major booksellers. I was also being contracted to do work
                  for companies like Intuit, Adobe, Google, and Amazon to name a few. After Steve Jobs single-handedly took down the Flash Platform (well, there were security issues too that never really seemed to get resolved), 
                  I found my way back to my trusty old friend Javascript, along with some Android and iOS development as well. 
                </Text>
                <Text variant='p'>
                  I was simultaneously doing project management and full-stack work with PHP/Laravel and Angular and feeling stuck and over-worked when I discovered React. I had reconnected with a past client that I had done advanced ActionScript and some Android development for. 
                  Understanding that I had no experience with React whatsoever, he offered to pay me to learn React and build a proof-of-concept SPA of their app that ran on Adobe AIR (pretty much defunct by this time) and Android native that he could then bring to the board of directors for further funding approval. 
                  The pay was terrible, but I didn't care. The long-term value of once again building high-performance single page web apps was so enticing that I left the work I was doing in favor of development with React.
                </Text>
                <Text variant='p'>
                  I believe that React is arguably the most evolutionary technology for web development in the last decade and the ever-evolving React ecosystem keeps me excited and motivated to write about the things I learn each and every day.
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Engineering'>
                <Card variant='paper'>
                  I currently spend most of my time with front-end web technologies, primarily React and the React ecosystem. 
                  I've been doing React development for about 5 years, but I started working with Javascript in 2007. 
                  On the back end, I have done a lot of work with PHP over the years and I've been spending some time learning C# and the .NET Core framework as of late.
                  I'm also pretty good with cloud technologies, having written a lot of articles for AWS and IBM in the past and I've been spending a good amount of time working with Azure lately.
                </Card>
              </Section>
              <Divider />
              <Section title='Technical Project Management'>
                <Card variant='paper'>
                  I took a little detour from development a couple of times to lead SaaS projects as a Project Manager, but ultimately my passion for programming cool stuff brought me back to engineering each time.
                  I hold two ISO-9000 accredited certifications for Project Management, the PMP® and the Agile Certified Practitioner (PMI-ACP®) from the Project Management Institute (PMI).
                </Card>
              </Section>
              <Divider />
              <Section title='UX and Product Development'>
                <Card variant='paper'>
                  I've always been fascinated by User Experience Design and the constantly evolving ways of creating delightful experiences. I spent six years early in my career with high-profile development agencies including Adobe Consulting 
                  and Gorilla Logic where I was able to perfect the craft.  
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
